import styles from "../css/app.pcss";
import { tns } from "tiny-slider/src/tiny-slider";

// App main
const main = async () => {
  // Import our CSS
  //const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
  // Async load the vue module
  // const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
  // Create our vue instance
  // const vm = new Vue.default({
  //     el: "#app",
  //     components: {
  //         'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
  //     },
  //     data: {
  //     },
  //     methods: {
  //     },
  //     mounted() {
  //     },
  // });
};
// Execute async function
main().then(value => {

  // html elements
  const contactPane = document.getElementById('contact-pane');
  const contactToggleOn = document.getElementById('contact-on');
  const contactToggleOff = document.getElementById('contact-off');

  // listeners
  contactToggleOn.onclick = () => contactPane.classList.add('show-address');
  contactToggleOff.onclick = () => contactPane.classList.remove('show-address');


  function checkPosition() {
    let windowY = window.scrollY;
    if (windowY > 5) {

      contactPane.classList.add('show');
      contactPane.classList.remove('hide');
      contactPane.classList.remove('show-address');
    } else {
      contactPane.classList.add('hide');
      contactPane.classList.remove('show');
    }
  }

  function debounce(func, wait = 10, immediate = true) {
    let timeout;
    return function() {
      let context = this, args = arguments;
      let later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  window.addEventListener('scroll', debounce(checkPosition));

  var sliderArray = document.querySelectorAll(".image-slider");
  sliderArray.forEach(function(el) {
    tns({
      container: el,
      mode: "gallery",
      mouseDrag: true,
      controls: false,
      nav: false,
      navPosition: false,
      items: 1,
      autoplay: true,
      autoplayButtonOutput: false
    });
  });

  function scrollTo() {
    const links = document.querySelectorAll('.scroll');
    links.forEach(each => (each.onclick = scrollAnchors));
  }
  
  function scrollAnchors(e, respond = null) {
    const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
    e.preventDefault();
    var targetID = (respond) ? respond.getAttribute('href') : this.getAttribute('href');
    const targetAnchor = document.querySelector(targetID);
    if (!targetAnchor) return;
    const originalTop = distanceToTop(targetAnchor);
    window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });
    const checkIfDone = setInterval(function() {
      const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
      if (distanceToTop(targetAnchor) === 0 || atBottom) {
        targetAnchor.tabIndex = '-1';
        targetAnchor.focus();
        window.history.pushState('', '', targetID);
        clearInterval(checkIfDone);
      }
    }, 100);
  }
  scrollTo();

});


// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
